<template>
  <div>
    <div class="guide">
      <ul>
        <li class="guide_top" @click="GoAbout">
          <p><i class="el-icon-chat-dot-round"></i></p>
          <span>关于我们</span>
        </li>
        <li class="guide_top" @click="GoProperty">
          <p><i class="el-icon-position"></i></p>
          <span>国家专利</span>
        </li>
        <li class="guide_top_one" @click="GoGd">
          <p><i class="el-icon-link"></i></p>
          <span>广东省中小企业融资平台</span>
        </li>
      </ul>

    </div>
  </div>
</template>
<!-- -->
<script>
export default {
  name: 'Floating',
  created() {
    
  },
  data() {
    return {}
  },
  methods: {
    GoAbout() {
      this.$router.push('about')
    },
    GoProperty() {
      var url = 'https://cpquery.cnipa.gov.cn/'
      window.open(url, '_blank')
    },
    GoGd() {
      var url = 'https://zxr.gdjr.gd.gov.cn/portal/static/main/index.html'
      window.open(url, '_blank')
    },
  },

}
</script>

<style lang="less" scoped>
.guide {
  width: 68px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  border: 1px solid #e0e0e0;
  color: #4f4f4f;
  box-shadow: 0 4px 11px -5px #9b9b9b;
  box-sizing: border-box;
  z-index: 10000;

  ul li:hover {
    background-color: #407FE0;
    color: #FFFFFF;
  }
}

.guide_top {
  height: 70px;
  text-align: center;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  i {
    // margin-top: 10px;
    font-size: 20px;
  }

  span {
    font-size: 12px;
  }

}

.guide_top_one {
  height: 90px;
  text-align: center;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  i {
    // margin-top: 10px;
    font-size: 20px;
  }

  span {
    font-size: 12px;
  }
}
</style>