export default {
    namespaced:true,
    state: {
        //用来修改提示框的显示或隐藏
        inShowToast: false,
        msg:"",
        type:""
    },
    mutations: {
        //修改inShowToast的值
        chanIsShowToast(state, payload) {
            state.inShowToast = payload.isShow
            state.msg = payload.msg
            state.type=payload.type
        }
    },
    actions: {
        asyncChanIsShowToast(context,payload){
            context.commit("chanIsShowToast",{
                isShow:true,
                 msg:payload.msg,
                 type:payload.type
             })
        
        setTimeout(()=>{
            context.commit('chanIsShowToast',{
                isShow:false,
                msg:payload.msg,
                type:payload.type
          })
          },2000)
        }
    },
}