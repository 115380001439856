import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
	{
		path: "*",
		redirect: "/home",
	},
	{
		path: "/home",
		name: "home",
		component: HomeView,
	},
	{
		path: "/property",
		name: "property",
		component: () => import(/* webpackChunkName: "property" */ "../views/Property.vue"),
	},
	{
		path: "/enterprise",
		name: "enterprise",
		component: () => import(/* webpackChunkName: "enterprise" */ "../views/Enterprise.vue"),
	},
	{
		path: "/bank",
		name: "bank",
		component: () => import(/* webpackChunkName: "bank" */ "../views/Bank.vue"),
	},
	{
		path: "/product-classify",
		name: "productClassify",
		component: () => import(/* webpackChunkName: "bank" */ "../views/product/Classify.vue"),
	},
	{
		path: "/product-list",
		name: "productList",
		component: () => import(/* webpackChunkName: "bank" */ "../views/product/List.vue"),
	},
	{
		path: "/product-details",
		name: "productDetails",
		component: () => import(/* webpackChunkName: "bank" */ "../views/product/Details.vue"),
	},

	{
		path: "/insure-list",
		name: "insureList",
		component: () => import(/* webpackChunkName: "bank" */ "../views/product/insure/List.vue"),
	},
	{
		path: "/insure-details",
		name: "insureDetails",
		component: () => import(/* webpackChunkName: "bank" */ "../views/product/insure/Details.vue"),
	},

	{
		path: "/finance",
		name: "finance",
		component: () => import(/* webpackChunkName: "finance" */ "../views/Finance.vue"),
	},

	{
		path: "/about",
		name: "about",
		component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
	},
	{
		path: "/bankingdetails",
		name: "bankingdetails",
		component: () => import(/* webpackChunkName: "bankingdetails" */ "../views/BankingDetails.vue"),
	},

	{
		path: "/financedetails",
		name: "financedetails",
		component: () => import(/* webpackChunkName: "financedetails" */ "../views/FinanceDetails.vue"),
	},
	{
		path: "/login",
		name: "login",
		component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
	},
	{
		path: "/service",
		name: "service",
		component: () => import(/* webpackChunkName: "service" */ "../views/Service.vue"),
	},
	{
		path: "/prospectus",
		name: "prospectus",
		component: () => import(/* webpackChunkName: "prospectus" */ "../views/Prospectus.vue"),
	},
	{
		path: "/technology",
		name: "technology",
		component: () => import(/* webpackChunkName: "technology" */ "../views/Technology.vue"),
	},
	{
		path: "/financing",
		name: "financing",
		component: () => import(/* webpackChunkName: "financing" */ "../views/Financing.vue"),
	},
	{
		path: "/servicedetails",
		name: "servicedetails",
		component: () => import(/* webpackChunkName: "servicedetails" */ "../views/ServiceDetails.vue"),
	},

	{
		path: "/financingdetails",
		name: "financingdetails",
		component: () => import(/* webpackChunkName: "financingdetails" */ "../views/FinancingDetails.vue"),
	},
	{
		path: "/technologydetails",
		name: "technologydetails",
		component: () => import(/* webpackChunkName: "technologydetails" */ "../views/TechnologyDetails.vue"),
	},
	{
		path: "/register",
		name: "register",
		component: () => import(/* webpackChunkName: "register" */ "../views/Register.vue"),
	},
	{
		path: "/promotioncenter",
		name: "promotioncenter",
		component: () => import(/* webpackChunkName: "PromotionCenter" */ "../views/PromotionCenter.vue"),
	},
	{
		path: "/promotioncenter-detail",
		name: "promotioncenterDetail",
		component: () => import(/* webpackChunkName: "PromotionCenterDetail" */ "../views/PromotionCenterDetail.vue"),
	},
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
