<template>
	<div
		class="corner-dialog"
		v-if="showDialog"
		:style="{
			width: typeof width === 'number' ? `${width}px` : width,
		}">
		<div class="header">
			<div class="close-btn" @click="close">
				<i class="el-icon-close"></i>
			</div>
		</div>
		<div class="body">
			<div class="show-wrapper" :style="{
                width: `calc(${typeof width === 'number' ? `${width}px` : width} - 24px)`,
            }">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CornerDialog",
	props: {
		width: {
			type: [String, Number],
			default: 200,
		},
	},
	data() {
		return {
			showDialog: true,
		};
	},
	methods: {
		close() {
			this.showDialog = false;
			this.$emit("close");
		},
	},
};
</script>

<style lang="less" scoped>
.corner-dialog {
	width: 200px;
	position: fixed;
	right: 0px;
	bottom: 30px;
	display: flex;
	flex-direction: column;

	.header {
		.close-btn {
			width: 24px;
			height: 24px;
			color: #aaa;
			border: 1px solid #aaa;
			background-color: transparent;
			border-radius: 50%;
			cursor: pointer;
			margin-left: auto;
			font-size: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.body {
		.show-wrapper {
			border-radius: 5px;
			overflow: hidden;
		}
	}
}
</style>
