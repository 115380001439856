<template>
	<div
		ref="ball"
		class="bouncing-ball"
		:style="{
			width: `${width}px`,
			height: `${height}px`,
		}"
		@mouseenter="stopAnimation"
		@mouseleave="startAnimation">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "BouncingBall",
	props: {
		width: {
			type: [Number],
			default: 200,
		},
		height: {
			type: [Number],
			default: 106,
		},
	},
	data() {
		return {
			x: 0,
			y: 0,
			dx: 2, // 水平方向速度
			dy: 2, // 垂直方向速度
			isHovering: false, // 是否正在悬停
			animationFrameId: null, // 用于存储requestAnimationFrame的ID
		};
	},
	mounted() {
		this.init();
		this.startAnimation();
	},
	beforeDestroy() {
		if (this.animationFrameId) {
			cancelAnimationFrame(this.animationFrameId); // 组件销毁时取消动画
		}
	},
	methods: {
		init() {
			// 初始化小球位置
			this.x = Math.random() * (window.innerWidth - this.width * 2) + this.width;
			this.y = Math.random() * (window.innerHeight - this.height * 2) + this.height;
		},
		startAnimation() {
			this.animate();
		},
		stopAnimation() {
			if (this.animationFrameId) {
				cancelAnimationFrame(this.animationFrameId); // 停止动画
				this.animationFrameId = null;
			}
		},
		animate() {
			// 动画循环
			const animateFrame = () => {
				if (!this.isHovering) {
					// 更新小球位置
					this.x += this.dx;
					this.y += this.dy;
					// 检查碰撞并反弹
					if (this.x - this.width < 0 || this.x > window.innerWidth) {
						this.dx = -this.dx; // 水平反弹
					}
					if (this.y - this.height - 30 < 0 || this.y - 30 > window.innerHeight) {
						this.dy = -this.dy; // 垂直反弹
					}
				}

				// 应用新位置
				this.$refs.ball.style.transform = `translate(${this.x - this.width}px, ${this.y - this.height}px)`;

				// 继续动画
				if (!this.isHovering) {
					this.animationFrameId = requestAnimationFrame(animateFrame);
				}
			};

			this.animationFrameId = requestAnimationFrame(animateFrame);
		},
	},
};
</script>

<style scoped>
.bouncing-ball {
	position: absolute;
	z-index: 9999;
	will-change: transform; /* 优化动画性能 */
}
</style>
