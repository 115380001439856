<template>
	<div>
		<div class="Top_bar W">
			<div class="img_box">
				<!-- <img src="../assets/logo.png" alt=""> -->
				<img src="../assets/logo_tow.png" alt="" />
				<h2>肇庆市科技创新服务平台</h2>
			</div>
			<span>
				<el-button @click="GoLogin" v-show="!showname">登录</el-button>

				<el-button @click="OutLogin" v-show="showname">退出</el-button>
				<el-button @click="GoRegister">注册</el-button>
				<el-button @click="UsingTutorials">使用教程</el-button>
			</span>
		</div>
		<div class="nav_bar">
			<div class="nav W">
				<ul>
					<li>
						<router-link to="/home" style="color: #fff; text-decoration: none">首页</router-link>
					</li>
					<li>
						<router-link to="/financing" style="color: #fff; text-decoration: none">
							<el-dropdown>
								<span class="el-dropdown-link"> 企业需求收集<i class="el-icon-arrow-down el-icon--right"></i> </span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item><p @click="GoRongzi">企业融资需求</p></el-dropdown-item>
									<el-dropdown-item><p @click="Gotechnology">企业技术需求</p></el-dropdown-item>
									<el-dropdown-item><p @click="Goprospectus">商业计划书</p></el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</router-link>
					</li>
					<li>
						<router-link to="/promotioncenter" style="color: #fff; text-decoration: none">
							<el-dropdown @command="jump">
								<span class="el-dropdown-link">
									广州生产力促进中心<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="commissions">查新委托</el-dropdown-item>
									<el-dropdown-item command="checkCite">论文查收查引</el-dropdown-item>
									<el-dropdown-item command="technologyService">科技成果评价服务</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</router-link>
					</li>
					<li>
						<router-link to="/property" style="color: #fff; text-decoration: none">知识产权查询</router-link>
					</li>
					<!--                    <li><router-link to="/bank" style="color:#fff; text-decoration: none;">银行信贷业务</router-link></li>-->
					<li>
						<router-link to="/product-classify" style="color: #fff; text-decoration: none">科技金融超市</router-link>
					</li>
					<li>
						<router-link to="/finance" style="color: #fff; text-decoration: none">科技政策宣传</router-link>
					</li>
					<li>
						<router-link to="/enterprise" style="color: #fff; text-decoration: none">创新能力评估</router-link>
					</li>
					<li>
						<router-link to="/service" style="color: #fff; text-decoration: none">合作机构</router-link>
					</li>
					<li>
						<router-link to="/about" style="color: #fff; text-decoration: none">关于我们</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<!--  -->
<script>
import axios from "axios";

export default {
	name: "HelloWorld",
	data() {
		return {
			showname: localStorage.getItem("token"), //判断是否已登录
		};
	},
	methods: {
		// 广州生产力促进中心点击下拉点击跳转
		jump(key) {
			this.$router.push({
				name: "promotioncenterDetail",
				query: {
					key,
				},
			});
		},
		GoRongzi() {
			this.$router.push("financing");
		},

		UsingTutorials() {
			axios({
				method: "get",
				url: "肇庆市科技创新服务平台使用教程.pdf",
				responseType: "blob",
			})
				.then((res) => {
					const blob = new Blob([res.data], { type: "application/pdf" });
					const a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					let d = new Date();
					a.download = "肇庆市科技创新服务平台使用教程.pdf";
					a.click();
					URL.revokeObjectURL(a.href);
					a.remove();
				})
				.catch((error) => {});
		},
		Gotechnology() {
			let token = localStorage.getItem("token");
			if (token) {
				this.$router.push("technology");
			} else {
				this.$message({
					message: "请先登录!",
					type: "warning",
				});
			}
		},
		Goprospectus() {
			let token = localStorage.getItem("token");
			if (token) {
				this.$router.push("prospectus");
			} else {
				this.$message({
					message: "请先登录!",
					type: "warning",
				});
			}
		},
		GoRegister() {
			this.$router.push("register");
		},
		GoLogin() {
			let token = localStorage.getItem("token");
			if (!token) {
				this.$router.push("login");
			}
		},
		OutLogin() {
			let token = localStorage.getItem("token");
			if (token) {
				localStorage.removeItem("token");
				this.$router.push("home");
				this.$message({
					message: "退出成功",
					type: "success",
				});
				this.showname = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.router-link-active {
	text-decoration: none;
}
a {
	text-decoration: none;
}
.el-dropdown-link {
	cursor: pointer;
	color: #fff;
	font-size: 16px;
	line-height: 50px;
}

.el-icon-arrow-down {
	font-size: 16px;
}

.W {
	width: 1200px;
	margin: auto;
}

.Top_bar {
	height: 70px;
	margin: auto;
	display: flex;
	justify-content: space-between;
}

span {
	line-height: 70px;

	.el-button {
		border-radius: 10px;
	}
}

.nav_bar {
	height: 50px;
	background-color: #407fe0;

	.nav {
		//margin-left: 205px;
		line-height: 50px;
		color: #fff;

		ul {
			display: flex;
			justify-content: center;

			li {
				margin-right: 40px;
				cursor: pointer;
			}
		}
	}
}

.nav_tow {
	margin-left: 334px;
	color: #fff;

	ul {
		display: flex;

		li {
			margin-right: 50px;
			cursor: pointer;
		}
	}
}

.img_box {
	display: flex;

	img {
		width: 100px;
		height: 70px;
	}

	h2 {
		line-height: 70px;
		font-weight: 300;
	}
}
</style>
