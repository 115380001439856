<template>
  <div>
    <div class="foot">
      <div class="foot_content W">
        <div class="website W">
          <el-button type="primary">友情链接</el-button>
          <ul>
            <li><a href="https://zxr.gdjr.gd.gov.cn/portal/static/main/index.html"
                   target="_blank">广东省中小企业融资平台</a></li>
            <li><a href="https://cpquery.cnipa.gov.cn/" target="_blank">中国及多国专利审查信息查询</a></li>
            <li><a href="https://gdstc.gd.gov.cn/" target="_blank">广东省科学技术厅</a></li>
            <li><a href="https://krdl.ip.top/" target="_blank">科技成果转化平台</a></li>
            <li><a href="https://www.zhaoqing.gov.cn/zqkjj/gkmlpt/index" target="_blank">肇庆市科学技术局</a></li>
          </ul>
        </div>
      </div>
      <div class="foot_message W">
        <ul>
          <li>
            <p class="title_one">网站信息</p>
            <p>帮助中心</p>
            <p>关于我们</p>
          </li>
          <li>
            <p class="title_one">联系我们</p>
            <p>0758-2899832</p>
            <p>[ 法定工作时间接听 ]</p>
          </li>

        </ul>
        <div class="img_box">
          <p>扫一扫关注公众号</p>
          <img src="../assets/qrcode.jpg" alt="">
        </div>
      </div>
      <div class="foot_host W">
        <span>主办：肇庆市生产力促进中心</span> 联系方式：0758-2899860 <span class="left"><a
          href="https://beian.miit.gov.cn" target="_blank">粤ICP备2023006038号</a></span>
        <span class="right"><img src="../assets/ygw.png" alt=""><a href="https://www.beian.gov.cn" target="_blank">粤公网安备44120202000625</a></span>
      </div>
    </div>
  </div>
</template>
<!--  -->
<script>
export default {
  name: 'foot',
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.W {
  width: 1200px;
  margin: auto;
}

.foot {
  // margin-top: 70px;
  height: 320px;
  width: 100%;
  background-color: #F1F2F3;
  padding: 30px 0;
  box-sizing: border-box;
  margin-top: 20px;
  float: left;

  .website {
    height: 35px;
    line-height: 35px;
    display: flex;

    .el-button {
      line-height: 10px;
      margin-right: 50px;
    }

    ul {
      display: flex;

      li {
        a {
          color: #000;
          margin-right: 30px;
          font-size: 12px;
          text-decoration: none
        }
      }
    }
  }

  .foot_message {
    display: flex;
    justify-content: space-between;

    ul {
      display: flex;

      li {

        width: 260px;
        height: 170px;
        margin-top: 20px;
        margin-right: 50px;
        background-color: #eaebeb;

        .title_one {
          font-weight: 700;
          font-size: 14px;
          margin-top: 25px;
          margin-bottom: 25px;
          margin-left: 40px;
        }

        p {
          font-size: 12px;
          margin-left: 40px;
          margin-bottom: 15px;
        }

      }
    }
  }

  .foot_host {
    margin-top: 25px;
    font-size: 12px;
    text-align: center;

    span {
      margin-right: 20px;
    }
  }
}

.img_box {
  margin-right: 200px;

  p {
    font-weight: 700;
    text-align: center;
    margin-bottom: 5px;
  }

  img {
    width: 200px;
    height: 180px;
  }
}

.left {
  margin-left: 20px;

  a {
    color: #000;
    margin-right: 30px;
    font-size: 12px;
    text-decoration: none
  }
}

.right {
  margin-left: -20px;

  a {
    color: #000;
    margin-right: 30px;
    font-size: 12px;
    text-decoration: none
  }
}
</style>