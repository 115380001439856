import Vue from 'vue'
import Vuex from 'vuex'
import ShowToast from "@/store/ShowToast"
Vue.use(Vuex)

export default new Vuex.Store({
 
  modules: {
   ShowToast
  }
})
