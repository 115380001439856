import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import ElementUI from 'element-ui'


// import 'element-ui/lib/theme-chalk/index.css'
import element from './elementPartUI'
import 'element-ui/lib/theme-chalk/index.css';
 
Vue.use(element)
// Vue.use(ElementUI)
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
router.afterEach((to,from,next)=>{ 
  window,scrollTo(0,0)
})

