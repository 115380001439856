<template>
  <div id="app">

    <router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>
  export default {
    
    data() {
      return {
        isRouterAlive: true
      };
    },
    provide() {
      return {
        reload: this.reload
      };
    },
    methods: {
      reload() {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
        });
      }
    }
  }
</script>
<style lang="less">
*{
  padding: 0;
  margin: 0;
  list-style: none;
}

</style>
