<template>
	<div class="home">
		<!-- <div class="banner"></div> -->
		<div class="home-inner">
			<HelloWorld />
			<el-carousel height="500px">
				<el-carousel-item v-for="(item, index) in carouseData" :key="index">
					<img :src="item.url" alt="" class="banner" />
				</el-carousel-item>
			</el-carousel>
			<div class="banner_img_center">
				<div class="service W">
					<h3>特色服务专区</h3>
					<p>根据不同融资场景与需求，定制专属融资服务</p>
				</div>
				<div class="characteristic W">
					<ul>
						<li class="service_mr" @click="gofinance">
							<img src="../assets/service1.png" alt="" />
							<h4>科技政策宣传</h4>
							<p>“科技政策宣传 1994年,第一届中国科技金融促进会上,首次提出了“科技金融”...</p>
						</li>
						<li class="service_mr" @click="goenterprise">
							<img src="../assets/service2.png" alt="" />
							<h4 class="title_one">企业创新能力评估</h4>
							<p>在这种大环境下,企业资质的评估正日益成 为无形资产评估领域中的新情况...</p>
						</li>
						<li class="service_mr" @click="GoBank">
							<img src="../assets/service3.png" alt="" />
							<h4 class="title_two">科技金融超市</h4>
							<p>信贷业务是商业银行最重要的资产业务，通过放款收回本金和利息，扣除成本...</p>
						</li>
						<li class="service_mr" @click="goproperty">
							<img src="../assets/service4.png" alt="" />
							<h4>知识产权查询</h4>
							<p>最主要的三种知识产权是著作权、专利权和商标权，其中专利权与商标权也被统称为工业产权...</p>
						</li>
					</ul>
				</div>
			</div>
			<div class="service W">
				<h3>知识产权</h3>
				<p>相关产权信息一目了然</p>
			</div>
			<div class="property W" @click="Goproperty">
				<div class="property_left"><img src="../assets/property1.png" alt="" /></div>
				<div class="property_right">
					<div class="property_right_top">
						<img src="../assets/property2.png" alt="" />
						<img src="../assets/property3.png" alt="" />
					</div>
					<div class="property_right_bottom">
						<img src="../assets/property4.png" alt="" />
					</div>
				</div>
				<div class="property_center">
					<img src="../assets/property9.png" alt="" />
				</div>
			</div>
			<div class="banner_img">
				<div class="service W">
					<h3>前沿咨询</h3>
					<p>一同了解行业相关咨询</p>
				</div>
				<div class="consulting W">
					<div class="consulting_nav">
						<ul>
							<li :class="isblock == true ? 'active' : ''" @click="isshow">制度法规</li>
							<li :class="isblock1 == true ? 'active' : ''" @click="isshow1">通知公告</li>
							<li :class="isblock2 == true ? 'active' : ''" @click="isshow2">行业咨询</li>
						</ul>
					</div>
				</div>
				<div class="consulting_message W" v-show="isblock">
					<ul>
						<li @click="GoFinance">
							<img src="../assets/consulting2.png" alt="" />
							<h4 class="title_meaasge">《肇庆市金融改革发展“十四五”规划》解读</h4>
							<p class="title_meaasge">
								　肇庆市委、市政府高度重视“十四五”金融改革发展工作，将《肇庆市金融改革发展“十四五”规划》（以下简称《金融规划》）列为重点专项规划之一。按照国家、省“十四五”金融工作部署，结合规划编制总体工作安排，肇庆市制定出台《金融规划》。
							</p>
						</li>
						<li @click="GoFinance">
							<img src="../assets/consulting3.png" alt="" />
							<h4>广东省促进文化和科技深度融合实施方案...</h4>
							<p>
								　为贯彻落实《关于促进文化和科技深度融合的指导意见》（国科发高〔2019〕280号），我厅联合省委宣传部、省委网信办、省财政厅、省文化和旅游厅、省广播电视局等六部门联合印发《广东省促进文化和科技深度融合实施方案（2021-2025年）
							</p>
						</li>
						<li @click="GoFinance">
							<img src="../assets/consulting4.png" alt="" />
							<h4>肇庆市科技孵化育成载体扶持办法</h4>
							<p>
								市人民政府近日印发《通知》，出台《肇庆市科技孵化育成载体扶持办法》，进一步促进我市科技孵化育成载体的发展，加快培育科技型企业，推动科技成果产业化。
							</p>
						</li>
					</ul>
				</div>
				<div class="consulting_message W" v-show="isblock1">
					<ul>
						<li @click="GoFinance">
							<img src="../assets/consulting5.png" alt="" />
							<h4 class="title_meaasge">关于组织申报2023年度肇庆市重点实验室（企业类）的通知</h4>
							<p class="title_meaasge">
								为加强科技创新基础能力建设，进一步完善我市实验室体系，全面提升我市知识创造和知识获取能力，服务创新驱动发展战略和经济社会高质量发展...
							</p>
						</li>
						<li @click="GoFinance">
							<img src="../assets/consulting6.png" alt="" />
							<h4>
								关于举办2023年第十二届中国创新创业大赛（广东•肇庆赛区）暨肇庆市第八届“星湖杯”创新创业大赛颁奖活动的通知
							</h4>
							<p>
								　在省科技厅和省生产力促进中心的指导下，在市委、市政府的支持下，2023年第十二届中国创新创业大赛（广东•肇庆赛区）暨肇庆市第八届“星湖杯”创新创业大赛（以下称“大赛”）经过初赛、复赛、尽职调查和决赛等环节，已圆满完成各项赛事
							</p>
						</li>
						<li @click="GoFinance">
							<img src="../assets/consulting7.png" alt="" />
							<h4>
								关于2023年第十二届中国创新创业大赛（广东•肇庆赛区）暨肇庆市第八届“星湖杯”创新创业大赛获奖结果的公告
							</h4>
							<p>
								2023年第十二届中国创新创业大赛（广东•肇庆赛区）暨肇庆市第八届“星湖杯”创新创业大赛（以下称“大赛”）经过初赛、复赛和决赛等环节，已圆满完成各项赛事。
							</p>
						</li>
					</ul>
				</div>
				<div class="consulting_message W" v-show="isblock2">
					<ul>
						<li @click="GoConsulting">
							<img src="../assets/consulting8.png" alt="" />
							<h4 class="title_meaasge">党建引领创新金融助力发展 市科技局举办政银企对接会</h4>
							<p class="title_meaasge">
								会议指出，今年是中国共产党建党一百周年，也是“十四五”规划的开局之年，大力营造科技金融服务科技型中小微企业创新创业的浓厚氛围，助力我市创新驱动发展“1133”工程、助推科技金融活水流向实体经济，为推动
							</p>
						</li>
						<li @click="GoConsulting">
							<img src="../assets/consulting9.png" alt="" />
							<h4>聆听科普好声音！这场比赛带大家“走进科技”</h4>
							<p>
								“防震减灾、元宇宙、碳中和……”一阵阵“科普好声音”在肇庆新区响起。7月27日，由肇庆市科学技术局、肇庆市教育局、肇庆市科学技术协会联合主办的2022年广东省科普讲解大赛肇庆市预赛在肇庆市港澳青年创新创业基地举行。
							</p>
						</li>
						<li @click="GoConsulting">
							<img src="../assets/consulting10.png" alt="" />
							<h4>市科技馆开展“奇妙科技·精彩少年”品牌活动</h4>
							<p>
								6月12日上午，肇庆市科技局联合肇庆市科协、肇庆市科技中心在市科技馆组织开展未成年人思想道德建设工作品牌“奇妙科技·精彩少年”科普活动。活动围绕主题“小小思维营”，通过培养孩子的思考能力及创造性思维，
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div class="search W">
				<el-button round @click="GoBank">查看更多+</el-button>
			</div>
			<div class="service W">
				<h3>特色融资需求</h3>
				<p>一些关于银行贷款信息...</p>
			</div>
			<div class="financing W">
				<ul>
					<li class="list_one">
						<h4>融资介绍</h4>
						<div class="smbox"></div>
						<p>银行 银行是目前债权融资的主要方式,其优点在于程序比较简单...</p>
					</li>
					<li @click="GoBank">
						<h4>线上小微易贷</h4>
						<div class="money">
							<p>融资金额</p>
							<span>50-1000万</span>
						</div>
						<div class="money">
							<p>融资期限</p>
							<span>12个月</span>
						</div>
						<div class="money">
							<p>担保方式</p>
							<span>信用担保</span>
						</div>
						<div class="money">
							<p>还款方式</p>
							<span>随借随还</span>
						</div>
						<div class="money">
							<p>贷款方式</p>
							<span>企业贷款</span>
						</div>
						<div class="money">
							<img src="../assets/bank3.png" alt="" />
						</div>
					</li>
					<li @click="GoBank">
						<h4>中银企E贷·银税贷</h4>
						<div class="money">
							<p>融资金额</p>
							<span>200万元</span>
						</div>
						<div class="money">
							<p>融资期限</p>
							<span>12个月</span>
						</div>
						<div class="money">
							<p>担保方式</p>
							<span>信用担保</span>
						</div>
						<div class="money">
							<p>还款方式</p>
							<span>随借随还</span>
						</div>
						<div class="money">
							<p>贷款方式</p>
							<span>企业贷款</span>
						</div>
						<div class="money">
							<img src="../assets/bank4.png" alt="" />
						</div>
					</li>
					<li @click="GoBank">
						<h4>流动金额贷款</h4>
						<div class="money">
							<p>融资金额</p>
							<span>500万元</span>
						</div>
						<div class="money">
							<p>融资期限</p>
							<span>18个月</span>
						</div>
						<div class="money">
							<p>担保方式</p>
							<span>信用担保</span>
						</div>
						<div class="money">
							<p>还款方式</p>
							<span>随借随还</span>
						</div>
						<div class="money">
							<p>贷款方式</p>
							<span>企业贷款</span>
						</div>
						<div class="money">
							<img src="../assets/bank5.png" alt="" />
						</div>
					</li>
				</ul>
			</div>
			<Floating />
			<foot />
		</div>
		<wall-rebound-dialog :width="200" :height="200" v-if="reboundDialogShow">
			<corner-dialog @close="reboundDialogShow = false">
				<div
					style="
						height: 170px;
						background-color: rgba(0, 0, 0, 0.5);
						color: #fff;
						font-size: 22px;
						display: flex;
						align-items: center;
						justify-content: center;
					">
					<el-image
						class="announcement-img"
						src="https://www.zqkjcxfw.com/img/shengchu.jpg"
						@click="jump('https://www.gzpc.org.cn/f')"></el-image>
				</div>
			</corner-dialog>
		</wall-rebound-dialog>
	</div>
</template>
<!--  -->
<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import foot from "@/components/foot.vue";
import Floating from "@/components/Floating.vue";
import CornerDialog from "@/components/dialog/CornerDialog.vue";
import WallReboundDialog from "@/components/dialog/WallReboundDialog.vue";

export default {
	components: {
		HelloWorld,
		foot,
		Floating,
		CornerDialog,
		WallReboundDialog,
	},
	data() {
		return {
			carouseData: [
				{ url: require("../assets/chart2.png") },
				{ url: require("../assets/chart1.png") },
				{ url: require("../assets/chart3.png") },
				{ url: require("../assets/chart4.png") },
			],
			isblock: true,
			isblock1: false,
			isblock2: false,

			// 公告窗口显示/隐藏
			reboundDialogShow: true,
		};
	},
	methods: {
		jump(url) {
			window.open(url);
		},
		Goproperty() {
			this.$router.push("property");
		},
		isshow() {
			this.isblock = true;
			this.isblock2 = false;
			this.isblock1 = false;
		},
		isshow1() {
			this.isblock = false;
			this.isblock1 = true;
			this.isblock2 = false;
		},
		isshow2() {
			this.isblock = false;
			this.isblock2 = true;
			this.isblock1 = false;
		},
		gofinance() {
			this.$router.push("finance");
		},
		goenterprise() {
			this.$router.push("enterprise");
		},
		goproperty() {
			this.$router.push("property");
		},
		GoBank() {
			this.$router.push("product-classify");
		},
		GoFinance() {
			this.$router.push("finance");
		},
		GoConsulting() {
			this.$router.push("finance");
		},
	},
};
</script>
<style lang="less" scoped>
.announcement-img {
	width: 100%;
	height: 100%;
	cursor: pointer;
}
.home {
	position: relative;
	width: 100vw;
	height: 100vh;
	overflow: hidden;

	.home-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		overflow-y: auto;
	}
}
.el-carousel {
	width: 1307px;
	// height:  500px;
	// margin-left: 120px;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
	// img{
	//   height:"432px";
	// }
}

.W {
	width: 1200px;
	margin: auto;
}

.banner_img_center {
	width: 100%;
	background: url(../assets/banner9.png) no-repeat center center;
	background-size: cover;
}

.banner_img {
	width: 100%;
	background: url(../assets/banner10.png) no-repeat center center;
	background-size: cover;
}

.banner {
	width: 100%;
	height: 500px;
	background-size: cover;
}

.service {
	height: 100px;
	text-align: center;
	// background-color:pink;
	padding: 20px;
	box-sizing: border-box;

	p {
		font-size: 12px;
		color: #999999;
		margin-top: 10px;
	}
}

.characteristic {
	margin-top: 10px;
	height: 180px;
	// background-color:pink;
	ul {
		display: flex;
		justify-content: center;
		align-items: center;

		.service_mr {
			cursor: pointer;
			margin-right: 50px;
		}

		li {
			width: 220px;
			text-align: center;

			h4 {
				margin-top: 5px;
			}

			.title_one {
				margin-top: 13px;
			}

			.title_two {
				margin-top: 17px;
			}

			p {
				font-size: 12px;
				color: #999999;
				padding: 0 10px;
				margin-top: 5px;
			}
		}
	}
}

.consulting {
	margin-bottom: 50px;
}

.consulting_nav {
	width: 535px;
	height: 40px;
	// background-color:pink;
	margin: auto;

	ul {
		line-height: 40px;
		display: flex;
		justify-content: center;

		li {
			text-align: center;
			height: 40px;
			width: 175px;
			border: 1px solid #ccc;
			margin-right: 10px;
			cursor: pointer;
		}

		.active {
			background-color: #407fe0;
			color: #fff;
		}
	}
}

.consulting_message {
	img {
		width: 385px;
		height: 250px;
	}

	height: 410px;
	// background-color:pink;
	ul {
		display: flex;
		justify-content: space-between;

		.title_meaasge {
			color: #407fe0;
		}

		li {
			cursor: pointer;
			width: 388px;

			h4 {
				margin: 20px 0;
			}

			p {
				font-size: 12px;
				color: #999999;
			}
		}
	}
}

.search {
	margin-bottom: 20px;

	.el-button {
		margin-left: 550px;
		color: #407fe0;
	}
}

.property {
	cursor: pointer;
	height: 300px;
	display: flex;
}

.property_left {
	height: 300px;
	margin-left: 100px;
	margin-right: 50px;
	background-color: skyblue;
}

.property_right {
	width: 295px;
	height: 300px;
	margin-right: 50px;
}

.property_right_top {
	display: flex;
	justify-content: space-between;
}

.property_right_bottom {
	margin-top: 10px;
}

.property_center {
	img {
		width: 295px;
		height: 300px;
	}
}

.financing {
	margin-bottom: 70px;

	ul {
		display: flex;

		.list_one {
			width: 300px;
			background: url(../assets/financing.png);

			h4 {
				color: #fff;
				text-align: center;
				margin-top: 60px;
			}

			.smbox {
				width: 25px;
				height: 5px;
				margin: auto;
				margin-top: 20px;
				background-color: #fff;
			}

			p {
				font-size: 12px;
				margin-top: 30px;
				padding: 0 60px;
				color: #fff;
			}
		}

		li {
			cursor: pointer;
			border: 1px solid #ccc;
			text-align: center;
			height: 370px;
			width: 270px;
			margin-right: 20px;

			h4 {
				margin-top: 35px;
				// margin-bottom: 40px;
			}

			.money {
				margin: auto;
				width: 170px;
				text-align: center;
				height: 50px;
				line-height: 50px;
				display: flex;
				justify-content: center;
				border-bottom: 1px dashed #eeeeee;

				p {
					margin-right: 20px;
					font-size: 12px;
					color: #999999;
				}

				span {
					font-weight: 700;
					color: #407fe0;
				}
			}
		}
	}
}
</style>
