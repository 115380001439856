import {
	Link,
	Divider,
	Button,
	Input,
	Dialog,
	Radio,
	Form,
	FormItem,
	Card,
	Message,
	Carousel,
	CarouselItem,
	Table,
	TableColumn,
	DatePicker,
	Pagination,
	Tabs,
	TabPane,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Select,
	Option,
	Image,
} from "element-ui";
const element = {
	install: function (Vue) {
		Vue.use(Option);
		Vue.use(Select);
		Vue.use(DropdownItem);
		Vue.use(DropdownMenu);
		Vue.use(Dropdown);
		Vue.use(Tabs);
		Vue.use(TabPane);
		Vue.use(Link);
		Vue.use(Divider);
		Vue.use(Button);
		Vue.use(Input);
		Vue.use(Dialog);
		Vue.use(Radio);
		Vue.use(Form);
		Vue.use(FormItem);
		Vue.use(Card);
		Vue.use(CarouselItem);
		Vue.use(Carousel);
		Vue.use(Table);
		Vue.use(TableColumn);
		Vue.use(DatePicker);
    Vue.use(Pagination);
    Vue.use(Image);
		Vue.prototype.$message = Message;
	},
};

export default element;
